<template>
  <b-container fluid id="about">
    <b-row class="page-content">
      <b-col>
        <h1 align="center">
          <strong>Mark Robitaille</strong>
        </h1>
        <p align="center" class="mt-0">204-330-4689 - robitai4@myumanitoba.ca</p>
        <h4 id="-education-" class=" mt-2 mb-0">
          <strong>EDUCATION</strong>
        </h4>
        <hr class="mt-0"/>
        <h5 id="-bachelor-of-science-computer-science-major-">
          <strong>Bachelor of Science, Computer Science Major (Co-op Option)</strong> -
          <em>Expected Graduation: April 2022</em>
        </h5>
          <strong>University of Manitoba</strong>
        <ul>
          <li>Cumulative GPA: 4.3/4.5</li>
          <li>Specialization: Human-Computer Interaction and Computer Graphics</li>
          <li>Minor in Psychology</li>
        </ul>
        <h4 id="-technical-skills-" class="mb-0">
          <strong>TECHNICAL SKILLS</strong>
        </h4>
        <hr class="mt-0"/>
        <b-row>
        <b-col sm="4">
          <ul class="mb-0">
            <li>Java</li>
            <li>PHP</li>
            <li>C/C++</li>
            <li>Processing</li>
            <li>OpenGL</li>
          </ul>
        </b-col>
        <b-col sm="4">
          <ul class="mb-0">
            <li>Javascript</li>
            <li>Vue.js</li>
            <li>React</li>
            <li>SQL</li>
            <li>Grails</li>
          </ul>
        </b-col>
        <b-col sm="4">
          <ul>
            <li>JIRA</li>
            <li>Git</li>
            <li>VS Code</li>
            <li>IntelliJ</li>
            <li>Visual Studio</li>
          </ul>
        </b-col>
        </b-row>
        <h4 id="-work-experience-" class="mb-0">
          <strong>RELEVANT EXPERIENCE</strong>
        </h4>
        <hr class="mt-0"/>
        <h5 id="-software-developer-co-op-work-term-2-_may-2020-august-2020_">
          <strong>Software Developer, Co-op Work Term 2</strong> -
          <em>May 2020-August 2020</em>
        </h5>
        <p class="mb-0">
          <strong>Bold Commerce</strong>
          <br />
          <em>In the Subscriptions team, we maintained an application that enables merchants to sell product subscriptions on their Shopify stores. My role consisted of full-stack development.</em>
        </p>
        <ul>
          <li>Supported the application by implementing changes to improve user and client experience, fixing bugs, developing new API endpoints, and updating documentation.</li>
          <li>Implemented features into the company’s internal web tools to provide customer support with easier and faster methods of fulfilling merchant requests.</li>
          <li>Developed more than 20 acceptance tests for the application using CodeceptJS and Puppeteer to ensure stability.</li>
        </ul>
        <h5 id="-programmer-co-op-work-term-1-_may-2019-august-2019_">
          <strong>Programmer, Co-op Work Term 1</strong> -
          <em>May 2019-August 2019</em>
        </h5>
        <p class="mb-0">
          <strong>Agriculture and Agri-Food Canada</strong>
          <br />
          <em>As a part of the Integrated Application Services team in the Information Systems Branch, my team worked on modernizing a web application for project management.</em>
        </p>
        <ul>
          <li>Utilized Vue.js and related libraries such as Vuex, Vue Router, and Bootstrap-Vue to develop a reactive single-page application.</li>
          <li>Developed a suite of unit tests for Vue.js components using Vue Test Utils and Jest.</li>
          <li>Interacted with a Grails back-end using REST API calls to retrieve and update project data for the Vue.js front-end.</li>
        </ul>
        <h4 id="-extracurricular-" class="mb-0">
          <strong>EXTRACURRICULAR</strong>
        </h4>
        <hr class="mt-0"/>
        <h5 id="-vice-president-cssa-computer-science-students-association-_march-2019-present_">
          <strong>Vice President, University of Manitoba CSSA (Computer Science Students’ Association)</strong> -
          <em>March 2019-Present</em>
        </h5>
        <ul>
          <li>Coordinating with a team to plan and host events (e.g. CSSA Game Jam, CS Mixer).</li>
          <li>Developing a website for the CSSA utilizing React.</li>
        </ul>
        <h5 id="-participant-cssa-game-jam-2021-_january-2021_">
          <strong>Participant, CSSA Game Jam 2021</strong> -
          <em>January 2021</em>
        </h5>
        <ul>
          <li>Developed a time management game in Unity called Unhealthy Study Habits Simulator in a team.</li>
        </ul>
        <h5 id="-participant-cssa-game-jam-_november-2019_">
          <strong>Participant, CSSA Game Jam</strong> -
          <em>November 2019</em>
        </h5>
        <ul>
          <li>Learned how to use Unity and developed a game called Caterpeeler in a team.</li>
        </ul>
        <h5 id="-participant-peg-jam-2019-_november-2019_">
          <strong>Participant, Peg Jam 2019</strong> -
          <em>November 2019</em>
        </h5>
        <ul>
          <li>Created an arcade-style game called Phase Dodger using Processing in a small team.</li>
        </ul>
        <h4 id="-academic-projects-" class="mb-0">
          <strong>ACADEMIC PROJECTS</strong>
        </h4>
        <hr class="mt-0"/>
        <h5 id="-family-folder-human-computer-interaction-1-_fall-2019_">
          <strong>Family Folder, Human-Computer Interaction 1</strong> -
          <em>Fall 2019</em>
        </h5>
        <ul>
          <li>Collaborated in a group to create a high-fidelity prototype of a family communication web application through user-centered design.</li>
          <li>Implemented the logic of the Vue.js application and contributed to the development of HTML templates and CSS.</li>
        </ul>
        <h5 id="-fitness-and-health-diary-software-engineering-1-_winter-2019_">
          <strong>Fitness and Health Diary, Software Engineering 1</strong> -
          <em>Winter 2019</em>
        </h5>
        <ul>
          <li>Developed an Android application as a team using version control software and agile methodologies.</li>
        </ul>
        <h4 id="-work-experience-" class="mb-0">
          <strong>OTHER WORK EXPERIENCE</strong>
        </h4>
        <hr class="mt-0"/>
        <h5 id="-software-developer-co-op-work-term-2-_may-2020-august-2020_">
          <strong>Team Leader</strong> -
          <em>January 2015-November 2016</em>
        </h5>
        <p class="mb-0">
          <strong>Cineplex</strong>
        </p>
        <ul class="mb-0">
          <li>Delegated tasks to a small team to accomplish goals efficiently in a fast-paced environment.</li>
          <li>Resolved problems as they occurred by personally engaging with customers to ensure that their satisfaction and needs were met.</li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Resume",
  data() {
    return {};
  },
  components: {}
};
</script>

<style scoped>
</style>